.App {
    font-family: sans-serif;
    text-align: center;
  }
  
.image  img {
    max-width: 350px;
    height: 5rem;
    padding: 0 15px;
    object-fit: cover;
  }
  
  img:last-of-type {
    padding-left: 0;
  }
  
  .inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;
  }
  
  .wrapper {
    position: absolute;
    display: flex;
  }
  
  section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
  }
  
  @keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }
  .inner{
    height: 30vh;
    background-color: rgb(222, 240, 234);
  }

