* {
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    font-family: "Poppins", sans-serif;

  }
  h1 {
    color: #fff;
    text-align: center;
    margin: 3em 0;
    font-size: 3em;
  }
  footer {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #fff;
    background: #223353;
  }
  footer ul {
    list-style: none;
  }
  footer p {
    color: #fff;
    max-width: 25em;
    font-size: 0.9em;
    line-height: 23px;
  }
  footer a {
    color: #fff;
    text-decoration: none;
  }
  footer .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    gap: 2em;
  }
  .brand {
    display: block;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .media-icons li,
  .services-icons li {
    display: inline-block;
    margin: 0.5em 0.5em 0.5em 0;
  }
  .media-icons a {
    font-size: 1.1em;
    width: 2em;
    height: 2em;
    border: 1px #fff solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  .media-icons a:hover {
    background: #fff;
    color: #111;
  }
  .services-icons a {
    font-size: 1.6em;
  }
  .services-icons a:hover {
    color: #94fbab;
  }
  .footer-bottom {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(138, 208, 153, 0.36);
    padding: 2em;
  }
  .footer .menu {
    margin-bottom: 1em;
  }
  .footer .menu li {
    display: inline-block;
    margin: 0 0.7em 0.7em 0;
    text-align: center;
  }
  .footer .menu a {
    transition: 0.3s;
    font-weight: 600;
  }
  .footer .menu a:hover {
    color: #94fbab;
  }
  .input-wrap {
    margin: 1em 0;
    display: flex;
  }
  .input-wrap input {
    padding: 0.5em;
    border: none;
    background: #20232a;
    color: #ccc;
  }
  .input-wrap input:focus {
    outline: none;
  }
  .input-wrap button {
    padding: 0.8em;
    background: #94fbab;
    border: none;
    color: #217634;
    transition: 0.3s;
  }
  .input-wrap button:hover {
    background: #fff;
    color: #111;
  }
  /* == responsive == */
  @media screen and (max-width: 900px) {
    .footer .container {
      flex-direction: column;
    }
  }