.container_product{

    display: grid;
    grid-template-columns: repeat(3,400px);

    grid-template-rows: 600px 600px 600px;
    grid-gap:30px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 800px){

.container_product{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
}
@media (max-width: 1115px){
    
    .container_product{
        grid-template-columns: repeat(2,400px);
        grid-template-rows: 600px 600px;
        grid-gap:80px;
    }
}

    
