.carasol_image{
    max-width: 100vw;
    max-height: 88vh;
    object-fit: cover;
    padding: 0 15px;
}
carousel-caption h5{
    font-size: 5rem;
}
carousel-caption p{
    font-size: 5rem;
}
@media only screen and (max-width:768px){
    
    carousel-caption h5{

    }
}